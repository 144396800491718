<template>
    <div :class="['navigation', {'navigation--closed': !isOpen}]">
        <router-link :to="{ name: 'dashboard' }" class="navigation__brand">
            <img src="/img/logo.svg" class="navigation__logo"/>
        </router-link>

        <nav class="navigation__menu">
            <router-link :to="{ name: 'dashboard' }" class="navigation__link">
                <FontIcon :icon="['fa', 'dashboard']" class="navigation__icon"/><span>Kokpit</span>
            </router-link>

            <!--
            <h3 class="navigation__group">Zapisy</h3>

            <router-link :to="{ name: 'clients.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'user-graduate']" class="navigation__icon"/>
                <span>Kursy</span>
            </router-link>

            <router-link :to="{ name: 'eventEnrollments.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'user-graduate']" class="navigation__icon"/>
                <span>Wydarzenia</span>
            </router-link>
            -->

            <h3 class="navigation__group">Szkoła</h3>

            <router-link :to="{ name: 'clients.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'user-graduate']" class="navigation__icon"/>
                <span>Klienci</span>
            </router-link>

            <router-link :to="{ name: 'courses.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'folder-closed']" class="navigation__icon"/>
                <span>Kursy językowe</span>
            </router-link>

            <router-link :to="{ name: 'events.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'calendar']" class="navigation__icon"/>
                <span>Wydarzenia</span>
            </router-link>

            <h3 class="navigation__group">Biuro</h3>

            <router-link :to="{ name: 'employees.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'people-group']" class="navigation__icon"/>
                <span>Pracownicy</span>
            </router-link>

            <router-link v-can="'office.browse-employees'" :to="{ name: 'classrooms.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'chalkboard']" class="navigation__icon"/>
                <span>Sale lekcyjne</span>
            </router-link>

            <!--
            <h3 class="navigation__group">Zarządzanie stroną</h3>

            <router-link :to="{ name: 'courses.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'chalkboard-user']" class="navigation__icon"/>
                <span>Kursy językowe</span>
            </router-link>

            <router-link :to="{ name: 'eventCategories.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'tags']" class="navigation__icon"/>
                <span>Kategorie wydarzeń</span>
            </router-link>

            <router-link :to="{ name: 'news.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'bullhorn']" class="navigation__icon"/>
                <span>Aktualności</span>
            </router-link>

            <router-link :to="{ name: 'pages.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'file-lines']" class="navigation__icon"/>
                <span>Strony</span>
            </router-link>

            <router-link :to="{ name: 'teachers.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'user-tie']" class="navigation__icon"/>
                <span>Prowadzący</span>
            </router-link>

            <router-link :to="{ name: 'reviews.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'star']" class="navigation__icon"/>
                <span>Opinie</span>
            </router-link>

            <router-link :to="{ name: 'images.index' }" class="navigation__link">
                <FontIcon :icon="['fa', 'image']" class="navigation__icon"/>
                <span>Zdjęcia</span>
            </router-link>
            -->
        </nav>
    </div>
</template>

<script setup>
import {watch} from 'vue';
import {useRoute} from 'vue-router';
import useNavigation from '@/composables/useNavigation';

const route = useRoute();
const {isOpen, close} = useNavigation();

watch(() => route.name, () => {
    close();
});

</script>