import catalog from '@/modules/catalog/routes';
import office from '@/modules/office/routes';
import clients from '@/modules/clients/routes';
import enrollments from '@/modules/enrollments/routes';
import school from '@/modules/school/routes';

export default [
    catalog,
    office,
    clients,
    enrollments,
    school,
];