export default [
    {
        path: '/courses',
        name: 'courses.index',
        component: () => import('@/modules/school/pages/courses/BrowseCourses')
    },
    {
        path: '/courses/:id',
        name: 'courses.show',
        component: () => import('@/modules/school/pages/courses/ShowCourse'),
        props: true,
    },
    {
        path: '/courses/create',
        name: 'courses.create',
        component: () => import('@/modules/school/pages/courses/ScheduleCourse'),
    },
    {
        path: '/courses/edit/:id',
        name: 'courses.edit',
        component: () => import('@/modules/school/pages/courses/EditCourse'),
        props: true,
    },
];