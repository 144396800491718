<template>
    <h1 class="heading--primary">Kokpit</h1>

    <!--
    <new-event-enrollments/>
    Nowe zapisy na kursy
    - język
    - jaki czas temu
    - pilność (na podstawie

    Nowe zapisy na wydarzenia
    -->
</template>