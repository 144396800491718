<template>
  <div class="form__group">
    <label :for="name" class="form__label">
      {{ label }}
    </label>
    <div class="form__input-wrapper form__phone">
      <span class="form__phone-dialling-code">+{{ diallingCode }}</span>
      <input class="form__input form__phone-number"
             :value="mobilePhoneNumber"
             :id="name"
             :name="name"
             @input="onInput">
    </div>

    <span class="form__error" v-if="errorMessage">
      <font-icon :icon="['fa', 'warning']" class="form__error-icon" /> {{ errorMessage }}
    </span>
    <span class="form__help" v-else-if="help">
      <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
    </span>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch, computed} from "vue";

const errorMessage = ref('');

const props = defineProps({
  modelValue: Number,
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  help: {
    type: String,
    required: false,
  },
  error: {
    type: String,
  }
});

const emit = defineEmits(['update:modelValue']);

const mobilePhoneNumber = computed(() => {
  return props.modelValue;
});

const diallingCode = computed(() => {
  return '48';
});

watch(() => props.error,(newValue) => {
  errorMessage.value = newValue;
}, { immediate: true });

const onInput = (event) => {
  errorMessage.value = '';
  emit('update:modelValue', event.target.value)
}

</script>