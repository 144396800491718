<template>
    <img :src="image.url[size]" :style="style" v-if="image">
</template>

<script setup>
import {computed, defineProps} from 'vue';

const props = defineProps({
    image: {
        type: Object,
        required: true
    },
    size: {
        type: String,
        required: true
    }
});

const style = computed(() => {
    if (props.image.brightness !== 100) {
        return {
            filter: 'brightness(' + props.image.brightness + '%)'
        }
    }

    return {};
});

</script>