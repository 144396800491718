import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import BaseLayout from '@/layouts/BaseLayout';
import DashboardLayout from '@/layouts/DashboardLayout';
import CommandLayout from '@/layouts/CommandLayout';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import FormButtons from '@/components/form/FormButtons';
import FormInput from "@/components/form/FormInput";
import FormText from '@/components/form/FormText';
import FormImage from '@/components/form/FormImage';
import FormSwitch from '@/components/form/FormSwitch';
import FormMoney from '@/components/form/FormMoney';
import FormDate from '@/components/form/FormDate';
import FormTime from '@/components/form/FormTime';
import FormSelect from '@/components/form/FormSelect';
import FormAjaxSelect from '@/components/form/FormAjaxSelect';
import FormPhone from '@/components/form/FormPhone';
import FormEditor from '@/components/form/FormEditor';
import Alert from '@/components/Alert';
import Spinner from '@/components/Spinner';
import Pagination from '@/components/Pagination';

import UrlPresenter from '@/components/presenters/UrlPresenter';
import LanguagePresenter from '@/components/presenters/LanguagePresenter';
import ImagePresenter from '@/components/presenters/ImagePresenter';
import ImageFieldPresenter from '@/components/presenters/ImageFieldPresenter';
import TextPresenter from '@/components/presenters/TextPresenter';
import DatePresenter from '@/components/presenters/DatePresenter';
import MoneyPresenter from '@/components/presenters/MoneyPresenter';
import BooleanPresenter from '@/components/presenters/BooleanPresenter';
import StatusPresenter from '@/components/presenters/StatusPresenter';
import PhoneNumberPresenter from '@/components/presenters/PhoneNumberPresenter';
import EmployeePresenter from '@/modules/office/components/presenters/EmployeePresenter';

import CommandPage from '@/pages/CommandPage';

import '../public/sass/app.scss';

library.add(fas, far);

const app = createApp(App).use(store).use(router);

app.component('BaseLayout', BaseLayout);
app.component('CommandLayout', CommandLayout);
app.component('DashboardLayout', DashboardLayout);
app.component('FontIcon', FontAwesomeIcon);
app.component('FormButtons', FormButtons);
app.component('FormInput', FormInput);
app.component('FormText', FormText);
app.component('FormDate', FormDate);
app.component('FormTime', FormTime);
app.component('FormImage', FormImage);
app.component('FormSwitch', FormSwitch);
app.component('FormMoney', FormMoney);
app.component('FormSelect', FormSelect);
app.component('FormAjaxSelect', FormAjaxSelect);
app.component('FormPhone', FormPhone);
app.component('FormEditor', FormEditor);
app.component('Alert', Alert);
app.component('Spinner', Spinner);
app.component('CommandPage', CommandPage);
app.component('Pagination', Pagination);

app.component('UrlPresenter', UrlPresenter);
app.component('LanguagePresenter', LanguagePresenter);
app.component('ImagePresenter', ImagePresenter);
app.component('ImageFieldPresenter', ImageFieldPresenter);
app.component('TextPresenter', TextPresenter);
app.component('DatePresenter', DatePresenter);
app.component('MoneyPresenter', MoneyPresenter);
app.component('BooleanPresenter', BooleanPresenter);
app.component('StatusPresenter', StatusPresenter);
app.component('PhoneNumberPresenter', PhoneNumberPresenter);
app.component('EmployeePresenter', EmployeePresenter);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        localStorage.setItem('employeeId', null);
        router.push({name: 'login'});
    } else if (error.response.status === 404) {
        //router.push({name: '404'});
    }

    return Promise.reject(error);
});

app.config.globalProperties.$http = axios;

// app.config.errorHandler = (err) => {
//     alert(err);
// };

app.mount('#app');

app.directive('can', {
    bind: function (el, binding, vnode) {
        vnode.elm.style.display = "none";
    }
})