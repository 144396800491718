export default [
    {
        path: '/employees',
        name: 'employees.index',
        component: () => import('@/modules/office/pages/employees/BrowseEmployees')
    },
    {
        path: '/employees/create',
        name: 'employees.create',
        component: () => import('@/modules/office/pages/employees/AddEmployee'),
    },
    {
        path: '/employees/:id',
        name: 'employees.show',
        component: () => import('@/modules/office/pages/employees/ShowEmployee'),
        props: true,
    },
    {
        path: '/employees/edit/:id',
        name: 'employees.edit',
        component: () => import('@/modules/office/pages/employees/EditEmployee'),
        props: true,
    },
    {
        path: '/classrooms',
        name: 'classrooms.index',
        component: () => import('@/modules/office/pages/classrooms/BrowseClassrooms')
    },
    {
        path: '/classrooms/create',
        name: 'classrooms.create',
        component: () => import('@/modules/office/pages/classrooms/AddClassroom'),
    },
];