<template>
    <div class="form__group">
        <label :for="name" class="form__label">
            {{ label }}
        </label>

        <Datepicker
            :model-value="formattedTime"
            @update:model-value="onChange"
            locale="pl"
            :time-picker="true"
            format="HH:mm"
            :start-time="{ minutes: 0}"
            :minutes-increment="minutesSpan"
            :month-change-on-scroll="false"
            cancel-text="Zamknij"
            select-text="Wybierz"
            preview-format=""
            ref="timepicker"
            utc>
            <template #dp-input="{ value }">
                <div class="form__input-wrapper form__date">
                    <input class="form__input form__date-input" :value="value" readonly
                           placeholder="Wybierz godzinę">
                    <a href="#" class="form__input-button">
                        <FontIcon :icon="[ 'fa', 'clock']"/>
                    </a>
                </div>
            </template>
            <template #clear-icon="{ clear }">
                <a href="#" class="form__input-clear" @click="clear">
                    <FontIcon :icon="['fa', 'times']"/>
                </a>
            </template>
            <template #action-select>
                <a href="#" class="button button--primary button--small" @click.prevent="selectTime">Wybierz</a>
            </template>
            <template #action-preview></template>
            <template #clock-icon>
                <font-icon :icon="['fa', 'clock']"/>  Ustaw godzinę
            </template>
        </Datepicker>

        <span class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
        </span>
        <span class="form__help" v-else-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
        </span>
    </div>
</template>

<script setup>
import {defineProps, ref, watch, computed} from 'vue';
import Datepicker from '@vuepic/vue-datepicker';

const props = defineProps({
    modelValue: String,
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    help: {
        type: String,
        required: false,
    },
    error: {
        type: String,
    },
    minutesSpan: {
        type: Number,
        default: 5
    }
});

const emit = defineEmits(['update:modelValue']);
const errorMessage = ref('');

const timepicker = ref();

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, { immediate: true });

const formattedTime = computed(() => {
    if (props.modelValue) {
        return {
            hours: Math.floor(props.modelValue / 60),
            minutes: props.modelValue % 60,
            seconds: 0,
        }
    }

    return null;
})

const selectTime = () => {
    timepicker.value.selectDate();
}

const onChange = (value) => {
    errorMessage.value = '';
    emit('update:modelValue', value.hours * 60 + value.minutes);
}

</script>