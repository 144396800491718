<template>
    <div class="form__group" ref="select">
        <label :for="name" class="form__label">
            {{ label }}
        </label>
        <div :class="['select', 'form__input-wrapper', {'select--is-open': isOpen, 'select--disabled': disabled}]">
            <!--<input v-model="searchText" @input="search" class="select__input" @focus="showResults = true">-->

            <div :class="['select__input', 'form__input', {'form__input--invalid': error}]"
                 @click.prevent="onToggle">
                <span v-if="selected">{{ options[selected] }}</span>
                <span v-else class="select__placeholder">{{ placeholder }}</span>
                <font-icon :icon="['fa', 'chevron-down']" class="select__icon" v-if="!disabled"/>
            </div>
            <div class="select__options" v-if="isOpen">
                <span class="select__option" v-for="(value, key) in props.options" :key="key"
                    @click.prevent="onSelect(key)">{{ value }}</span>
            </div>
        </div>
        <span class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
        </span>
        <span class="form__help" v-else-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
        </span>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch, onBeforeUnmount} from 'vue';

const props = defineProps({
    modelValue: String,
    label: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    options: {
        type: Object,
        required: true,
    },
    placeholder: {
        type: String,
        default: 'wybierz opcję'
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
    },
    help: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);
const selected = ref(props.modelValue);
const select = ref(null);

const errorMessage = ref('');

const onWindowClick = (e) => {
    if (!select.value.contains(e.target)) {
        isOpen.value = false;
    }
}

window.addEventListener('click', onWindowClick);

onBeforeUnmount(() => {
    window.removeEventListener('click', onWindowClick);
})

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, { immediate: true });

const onSelect = (key) => {
    isOpen.value = false;
    selected.value = key;
    emit('update:modelValue', key);
}

const onToggle = () => {
    if (!props.disabled) {
        isOpen.value = !isOpen.value;
    }
}

</script>