<template>
    <div class="form__group" ref="select">
        <label class="form__label">
            {{ label }}
        </label>
        <div :class="['select', 'form__input-wrapper', {'select--is-open': isOpen, 'select--disabled': disabled}]">
            <div :class="['select__input', 'form__input', {'form__input--invalid': error}]"
                 @click.prevent="onToggle">
                <span v-if="modelValue">
                    <slot name="selected" :item="selectedItem">{{ selectedItem[valueField] }}</slot>
                </span>
                <span v-else class="select__placeholder">{{ placeholder }}</span>
                <a v-if="modelValue" href="#" class="form__input-clear" @click.stop.prevent="onClear">
                    <FontIcon :icon="['fa', 'times']"/>
                </a>
                <font-icon :icon="['fa', 'chevron-down']" class="select__icon" v-if="!disabled"/>
            </div>
            <div class="select__options" v-if="isOpen">
                <div class="select__search">
                    <input ref="searchInput" v-model="searchText" @input="onSearch" class="form__input" placeholder="Szukaj...">
                    <spinner size="small" v-show="loading"/>
                </div>
                <span class="select__option" v-for="option in options" :key="option[keyField]"
                      @click.prevent="onSelect(option)">
                    <slot name="result" :item="option">{{ option[valueField] }}</slot>
                </span>
            </div>
        </div>
        <span class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
        </span>
        <span class="form__help" v-else-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
        </span>
    </div>
</template>

<script setup>
import {defineEmits, defineProps, onBeforeUnmount, ref, watch, nextTick, onMounted} from 'vue';

const props = defineProps({
    modelValue: String,
    initialModel: {
        type: Object,
        default: null,
    },
    label: {
        type: String,
        required: true,
    },
    options: {
        type: Object,
        required: true,
    },
    keyField: {
        type: String,
        required: true,
    },
    valueField: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        required: true,
        default: 'wybierz opcję'
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
    },
    help: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['update:modelValue', 'search']);

const loading = ref(false);
const isOpen = ref(false);
const select = ref(null);

const searchInput = ref(null);
const searchText = ref('');

const errorMessage = ref('');
const selectedItem = ref(props.initialModel);

onMounted(() => {
    onSearch();
});

const onWindowClick = (e) => {
    if (!select.value.contains(e.target)) {
        isOpen.value = false;
    }
}

window.addEventListener('click', onWindowClick);

onBeforeUnmount(() => {
    window.removeEventListener('click', onWindowClick);
})

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, { immediate: true });

const onSelect = (option) => {
    isOpen.value = false;
    selectedItem.value = option;
    emit('update:modelValue', option[props.keyField]);
}

const onToggle = () => {
    if (props.disabled) {
        return;
    }

    isOpen.value = !isOpen.value;

    if (isOpen.value === true) {
        nextTick(() => {
            searchInput.value.focus();
        });
    }
 }

const onClear = () => {
    emit('update:modelValue', null);
}

const onSearch = () => {
    emit('search', searchText.value, loading);
}

</script>