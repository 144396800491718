<template>
    <div class="dashboard" v-if="loaded">
        <the-navigation/>
        <div class="dashboard__content">
            <the-header/>
            <main class="dashboard__main">
                <div class="container">
                    <slot></slot>
                </div>
            </main>
        </div>
    </div>
    <command-screen ref="command"/>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue';
import TheHeader from '@/components/TheHeader.vue';
import TheNavigation from '@/components/TheNavigation.vue';
import {useAuth} from '@/services/auth/auth';
import CommandScreen from '@/pages/CommandScreen';

const auth = useAuth();
const loaded = ref(false);
const command = ref(null);

onBeforeMount(async () => {
    const {isSuccess} = await auth.fetchEmployee();
    window.commandService = command.value;

    if (isSuccess) {
        loaded.value = true;
    }
});
</script>