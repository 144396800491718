<template>
    <div class="form__group">
        <label :for="name" class="form__label">
            {{ label }}
        </label>

        <Datepicker
            :model-value="modelValue"
            @update:model-value="onChange"
            locale="pl"
            :format="dateFormat()"
            :enable-time-picker="withTime"
            :start-time="{ minutes: 0}"
            :minutes-increment="minutesSpan"
            :month-change-on-scroll="false"
            cancel-text="Zamknij"
            select-text="Wybierz"
            preview-format=""
            ref="datepicker"
            utc>
            <template #dp-input="{ value }">
                <div class="form__input-wrapper form__date">
                    <input class="form__input form__date-input" :value="value" readonly
                           placeholder="Wybierz datę">
                    <a href="#" class="form__input-button">
                        <FontIcon :icon="[ 'fa', 'calendar-alt']"/>
                    </a>
                </div>
            </template>
            <template #clear-icon="{ clear }">
                <a href="#" class="form__input-clear" @click="clear">
                    <FontIcon :icon="['fa', 'times']"/>
                </a>
            </template>
            <template #action-select>
                <a href="#" class="button button--primary button--small" @click.prevent="selectDate">Wybierz</a>
            </template>
            <template #action-preview></template>
            <template #clock-icon>
                <font-icon :icon="['fa', 'clock']"/>  Ustaw godzinę
            </template>
            <template #calendar-icon>
                <font-icon :icon="['fa', 'calendar']"/> Ustaw datę
            </template>
        </Datepicker>

        <span class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
        </span>
        <span class="form__help" v-else-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
        </span>
    </div>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import Datepicker from '@vuepic/vue-datepicker';

const props = defineProps({
    modelValue: String,
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    help: {
        type: String,
        required: false,
    },
    error: {
        type: String,
    },
    yearsShift: {
        type: Number,
        required: false,
        default: 0,
    },
    yearsBackward: {
        type: Number,
        required: false,
        default: 100,
    },
    yearsForward: {
        type: Number,
        required: false,
        default: 100,
    },
    startYearShiftFromCurrent: {
        type: Number,
        required: false,
        default: 0,
    },
    dateRange: {
        type: String,
        default: 'any',
        validator(value) {
            return ['past', 'any', 'future'].includes(value)
        }
    },
    withTime: {
        type: Boolean,
        default: false
    },
    minutesSpan: {
        type: Number,
        default: 5
    }
});

const emit = defineEmits(['update:modelValue']);

const datepicker = ref();
const errorMessage = ref('');

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, { immediate: true });

const selectDate = () => {
    datepicker.value.selectDate();
}

const dateFormat = () => {
    return props.withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';
}

const onChange = (value) => {
    errorMessage.value = '';

    if (props.withTime) {
        emit('update:modelValue', value);
    } else {
        emit('update:modelValue', value.substring(0, 10));
    }
}

</script>