<template>
    <div class="pagination">
        <template v-for="index in Math.ceil(total/limit)" :key="index">
            <a :class="['pagination__page', {'pagination__page--selected': (page === index)}]"
               href="#" @click.prevent="changePage(index)">{{ index }}</a>
        </template>
    </div>
</template>

<script setup>
import {defineEmits, defineProps, ref} from 'vue';

const emit = defineEmits(['pageChanged']);

const props = defineProps({
    page: {
        type: Number,
        required: true,
    },
    limit: {
        type: Number,
        required: true,
    },
    total: {
        type: Number,
        required: true,
    },
});

const currentPage = ref(props.page);

const changePage = (index) => {
    currentPage.value = index;
    emit('pageChanged', currentPage.value);
}

</script>