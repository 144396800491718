<template>
    <div class="command" v-show="isActive">
        <a href="#" class="command__close" @click.prevent="onClose">
            <font-icon :icon="['fa', 'close']"/>
        </a>
        <div class="command__body">
            <component
                :key="key"
                :is="currentComponent"
                v-bind="currentParams"
                @cancelled="onClose"
                @submitted="onSubmit">
            </component>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import {v4 as uuidv4} from 'uuid';

const currentComponent = ref(null);
const currentParams = ref({});
const isActive = ref(false);
const key = ref(null);

let submit = null;
let cancel = null;

const open = (component, params = {}, submitCallback = null, cancelCallback = null) => {
    key.value = uuidv4();
    currentComponent.value = component;
    currentParams.value = params;
    submit = submitCallback;
    cancel = cancelCallback;
    isActive.value = true;
}

const onClose = () => {
    if (cancel) {
        cancel();
    }
    isActive.value = false;
}

const onSubmit = (payload) => {
    if (submit) {
        submit(payload);
    }
    isActive.value = false;
}

defineExpose({
    open
});
</script>