import useApi from '@/services/api/useApi';
import useBranch from '@/services/branch/branch';
import {reactive} from 'vue';
import {useRouter} from 'vue-router';

const state = reactive({
    employee: null,
});

export function useAuth() {
    const api = useApi();
    const branch = useBranch();
    const router = useRouter();

    const csrf = async () => {
        const {error} = await api.get('csrf-cookie');

        console.log(error);
    }

    const login = async (email, password) => {
        await csrf();

        const {isSuccess, data, errors} = await api.post('office/employees/login', {
            email: email,
            password: password
        });

        let showBranchScreen = false;

        if (isSuccess.value) {
            const {data} = await fetchEmployee();
            showBranchScreen = data.value.branches.length > 1;
        }

        return {isSuccess, data, errors, showBranchScreen};
    }

    const logout = async () => {
        const {response} = await api.post('office/employees/logout');

        if (response.value.status === 200) {
            router.push({name: 'login'})
        }
    }

    const fetchEmployee = async () => {
        const {data, isSuccess} = await api.get('office/employees/me');

        if (isSuccess) {
            state.employee = data.value;
            localStorage.setItem('employeeId', state.employee.id);
            branch.setAvailableBranches(data.value.branches);
            if (data.value.branches.length > 1) {
                let branchId = branch.getCurrentBranchId();

                if (!branchId) {
                    // redirect to /branch
                }
            } else {
                branch.setCurrentBranchId(data.value.branches[0].id);
            }
        }

        return {data, isSuccess};
    }

    function getEmployee() {
        return state.employee;
    }

    function isAuthenticated() {
        return localStorage.getItem('employeeId') !== null;
    }

    return {login, logout, getEmployee, isAuthenticated, fetchEmployee};
}